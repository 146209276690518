import { graphql } from "gatsby";
import React from "react";

import {
	Content,
	IntroHolder,
	TwoColumns,
} from "@bahaide/shared/components/InnerPage";

import InnerPage from "../../../components/InnerPage";

import { OutboundLink } from "gatsby-plugin-google-analytics";

const Anreise = ({ data }) => (
	<InnerPage site="hda" title="Besucherinformationen" description="">
		<IntroHolder
			title="Besucherinformationen"
			picture={data.introImage}
			breadcrumbs={{
				parent: "Haus der Andacht",
				"current-item": "Besucherinformationen",
			}}
		/>

		<TwoColumns>
			<Content>
				<div>
					<p>
						<b>Energiesparmaßnahmen am Haus der Andacht</b>
					</p>

					<p>
						Um einen Beitrag zur Energieeinsparung zu leisten, werden die
						Heiztemperaturen im Haus der Andacht generell gedrosselt. Im
						Besucherzentrum wird die Temperatur auf 19 Grad beschränkt. Ebenso
						wird die Beleuchtung des Hauses der Andacht an Feiertagen begrenzt.
					</p>

					<p>Besucher werden gebeten sich entsprechend zu kleiden.</p>

					<h2>Adresse</h2>
					<p>
						Bahá’í-Haus der Andacht
						<br />
						Eppsteiner Str. 95
						<br />
						D-65719 Hofheim-Langenhain
						<br />
						Tel: 06192 – 901677
						<br />
						Email:{" "}
						<a href="mailto:hausderandacht@bahai.de">hausderandacht@bahai.de</a>
					</p>
					<h2>Besuchszeiten Haus der Andacht</h2>

					<p>
						1. April – 31. Oktober: täglich 9:00 – 20:00 Uhr
						<br />
						1. November – 31. März: täglich 9:00 – 18:00 Uhr
					</p>

					<h2>Öffnungszeiten Besucherzentrum</h2>
					<p>
						Montags - Sonntags: 10:00 Uhr - 17:00 Uhr
					</p>

                    <h2>Öffnungszeiten Buchladen</h2>
					<p>
						Montags - Freitags: 13:00 Uhr - 17:00 Uhr
						<br />
						Samstags & Sonntags: 10:00 Uhr - 17:00 Uhr
					</p>

					<h2>Wegbeschreibung</h2>

					<div className="google-maps">
						<iframe
							title="Haus der Andacht Landkarte"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d823.7821055032554!2d8.396287458584288!3d50.11314740700387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bda373f8a0fced%3A0x39119dfb20bc1489!2sBah%C3%A1'%C3%AD+Haus+der+Andacht+-+europ%C3%A4isches+Gebetshaus+der+Bah%C3%A1'%C3%AD-Religion!5e0!3m2!1sde!2sus!4v1488364610971"
							width="600"
							height="450"
							frameborder="0"
							allowfullscreen="allowfullscreen"
						/>
					</div>
					<h3>Mit dem Auto</h3>
					<h4>Von Frankfurt/Main</h4>
					<p>
						Autobahn A66 Richtung Wiesbaden<br></br>
						Ausfahrt „Hattersheim/Kriftel“ oder „Diedenbergen“
						<br></br>
						Richtung Hofheim bzw. Richtung: Langenhain<br></br>
						Hinweisschildern „Bahá’í“ folgen
					</p>
					<h4>Von Frankfurt Flughafen</h4>
					<p>Bundesstraße B43; Richtung A66 Wiesbaden. Weiter wie oben.</p>
					<h4>Von Köln</h4>
					<p>
						A3 Richtung Frankfurt; am Wiesbadener Kreuz auf A66 Richtung
						Frankfurt.<br></br>
						Ausfahrt „Diedenbergen“ und den Schildern Richtung Langenhain
						folgen.<br></br>
						Hinweisschildern „Bahá’í“ folgen
					</p>
					<h3>Mit öffentlichen Verkehrsmitteln</h3>
					<h4>Von Frankfurt (Main) Hauptbahnhof</h4>
					<p>
						Tiefbahnhof mit S-Bahn S2 Richtung „Niedernhausen“ bis „Hofheim“.
						<br></br>
						Busbahnhof Hofheim Bus Nummer 403 Richtung „Wildsachsen“.<br></br>
						Ausstieg: Haltestelle „Langenhain Siedlung“. Gehweg rechts
						Eppsteiner Strasse; das Haus der Andacht erreichen Sie nach 5
						Minuten.
					</p>
					<p>Preise: Frankfurt – Hofheim-Langenhain ca. 5,00€.</p>
					<p>
						<OutboundLink href="https://www.rmv.de/c/de/start/main-taunus/">
							RMV.DE - Webseite der Main-Taunus-Verkehrsgesellschaft
						</OutboundLink>
					</p>
					<p>
						<em>Abends und an Sonn- und Feiertagen</em>
						<br></br>
						Bestellung eines Anrufsammeltaxi vom Bahnhof Hofheim möglich.
						Langenhain (ca. 1,70€ - 2,60€ pro Person).
						<br></br>
						Telefon Anrufsammeltaxi (AST): 06192 – 200 26 26
					</p>
					<p>
						<a href="https://www.rmv.de/c/fileadmin/lno/mtv/pdf_dokumente/ast_hofheim.pdf">
							Weitere Informationen (PDF)
						</a>{" "}
						zur Bestellung eines Anrufsammeltaxis.{" "}
					</p>
					<p>Taxi von Hofheim nach Langenhain ca. 15,00€.</p>
					<h4>Ab Flughafen Frankfurt „Ankunft“</h4>
					<p>
						Bus X17 bis Hofheim Bahnhof
						<br />
						Umstieg in Bus 403 bis Langenhain Siedlung (Endhaltestelle)
						<br />
						Fahrtzeit ca. 1 Stunde
					</p>
					<h3>Über Wanderwege</h3>
					<iframe
						title="Haus der Andacht Wanderwege"
						src="https://www.outdooractive.com/de/route/wanderung/taunus/rundweg-in-das-thierbachtal/100269105/embed.html?flexView=false"
						width="100%"
						height="472px"
						frameborder="0"
						allowfullscreen="allowfullscreen"
					/>
					<h2>Aufenthalt und Übernachtungsmöglichkeiten am Haus der Andacht</h2>
					<ul>
						<li>
							<OutboundLink href="/dokumente/uebernachtungsmoeglichkeiten-haus-der-andacht.pdf">
								Übernachtungsmöglichkeiten (PDF)
							</OutboundLink>
						</li>
						<li>
							<OutboundLink href="https://www.hofheim.de/tourismus/Unterkunft_und_Uebernachten/index.php">
								Regelungen der Stadt Hofheim
							</OutboundLink>
						</li>
					</ul>
				</div>
			</Content>
		</TwoColumns>
	</InnerPage>
);

export default Anreise;

export const pageQuery = graphql`
    query {
        introImage: file(relativePath: { eq: "DSC08306.jpg" }) {
            ...fluidImage
        }
        flyerImage: file(
            relativePath: { eq: "Religioese_Naturschutztage_int.png" }
        ) {
            ...fluidImage
        }
        flyerImageTwo: file(
            relativePath: { eq: "HDA_BesondereAndachten_Sept.2021.png" }
        ) {
            ...fluidImage
        }
    }
`;
